<template>
  <div class="project-detail-view" :style="`width: 290px`">
    <div
      :style="`left: ${$route.query.action? 0: -56}px`"
      class="detail-title">
      <img
        class="icon icon-back"
        @click="backToList"
        src="/img/project/icon-back.png"
        alt=""
      />
      {{ type === "add"  ? "添加项目" : type ==="edit" ? "编辑项目" : title }}
    </div>
    <!-- 展示项目 -->
    <div v-if="type != 'add' && type != 'edit'" class="scroll-box-show">
      <div class="progress-box">
        <div class="progress-title">
          {{$t('forestGrass.progress')}}
        </div>
        <div class="progress-line">
          <span class="progress-line-label">{{$t('duration')}}</span>
          <el-progress :percentage="durationRatio"></el-progress>
        </div>
        <div class="progress-line">
          <span class="progress-line-label">{{$t('area')}}</span>
          <el-progress :percentage="areaRatio"></el-progress>
        </div>
      </div>
      <!-- 目标时长 -->
      <div class="show-content">
        <div class="show-content-left">
          <p class="show-content-title">
            <span>{{$t('forestGrass.targetDuration')}}</span>
          </p>
          <p class="show-content-value">
            {{ targetDuration }}
          </p>
        </div>
        <div class="show-content-right">
          <p class="show-content-title">
            <span>{{$t('forestGrass.finishDuration')}}:</span>
          </p>
          <p class="show-content-value">
            {{ inspectedDuration }}
          </p>
        </div>
      </div>
      <!-- 巡检面积 -->
      <div class="show-content">
        <div class="show-content-left">
          <p class="show-content-title">
            <span>{{$t('forestGrass.AreaKM')}}</span>
          </p>
          <p class="show-content-value">
           {{showAreaKM}}
          </p>
        </div>
        <div class="show-content-right">
          <p class="show-content-title">
            <span>{{$t('forestGrass.AreaMU')}}</span>
          </p>
          <p class="show-content-value">
            {{showAreaMU}}
          </p>
        </div>
      </div>
      <!-- 巡检总里程 -->
      <div class="show-content">
        <div class="show-content-left">
          <p class="show-content-title">
            <span>{{$t('forestGrass.mileage')}}</span>
          </p>
          <p class="show-content-value">
            {{ (detailInfo.inspectionMileage/1000).toFixed(2) }}km
          </p>
        </div>
        <div class="show-content-right">
          <p class="show-content-title">
            <!-- <span>{{$t('forestGrass.warnCount')}}</span> -->
          </p>
          <p class="show-content-value">
            <!-- {{ detailInfo.inspectionMileage }} -->
          </p>
        </div>
      </div>
      <!-- 日期 -->
      <div class="show-content">
        <div class="show-content-left">
          <p class="show-content-title">
            <span>{{$t('startTime')}}</span>
          </p>
          <p class="show-content-value">
            {{ startTime }}
          </p>
        </div>
        <div class="show-content-right">
          <p class="show-content-title">
            <span>{{$t('endTime')}}</span>
          </p>
          <p class="show-content-value">
            {{ endTime }}
          </p>
        </div>
      </div>
    </div>
    <!-- 新增项目 -->
    <div v-else class="scroll-box-edit">
      <div class="from-box">
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>项目名称</span>
          </p>
          <div class="name-content">
            <el-input v-model="form.projectName" placeholder="请输入项目名称" :maxlength="20"></el-input>
          </div>
        </div>
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>项目所属团体</span>
          </p>
          <div class="name-content">
            <select-tree
              :multiple="true"
              :data="projectTeamOptions"
              v-model="form.deptIds"/>
          </div>
        </div>
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>项目负责人</span>
          </p>
          <div class="name-content">
            <el-select v-model="form.projectLeaderId" placeholder="请选择项目负责人">
              <el-option v-for="option in projectLeaderOptions" :key="option.value" :label="option.label"
                :value="option.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>开始时间</span>
          </p>
          <div class="name-content">
            <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="form.startTime" type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </div>
        </div>
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>结束时间</span>
          </p>
          <div class="name-content">
            <el-date-picker v-model="form.endTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              :default-time="'23:59:59'" placeholder="选择结束时间">
            </el-date-picker>
          </div>
        </div>
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>{{$t('forestGrass.targetDuration')}}</span>
          </p>
          <div class="name-content">
            <el-input-number
              v-model="form.targetDuration"
              style="width: calc(100% - 40px)"
              :placeholder="$t('inputPlease')"
              :controls="false">
            </el-input-number>
            <span>小时</span>
          </div>
        </div>
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>{{$t('forestGrass.targetArea')}}</span>
          </p>
          <div class="name-content">
            <el-input-number
              v-model="form.targetArea"
              style="width: calc(100% - 40px);"
              :placeholder="$t('inputPlease')"
              :controls="false">
            </el-input-number>
            <span>km²</span>
          </div>
        </div>
        <div class="from-name">
          <p class="name-title">
            <span><a class="required">*</a>项目成员</span>
          </p>
          <div class="name-content">
            <el-select v-model="form.userIds" placeholder="请选择项目成员" multiple>
              <el-option v-for="option in projectLeaderOptions" :key="option.value" :label="option.label"
                :value="option.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="finish">
        <el-button
          :loading="saveLoading"
          class="finish-btn"
          type="primary"
          @click="handleFinish">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updateProject,
  getProjectTeamOptions,
  getProjectDetail ,
} from "@/api/forestGrass/index";
import { getUser } from '@/api/system/dept'
import { mapGetters } from "vuex";

export default {
  name: "	FG_detail",
  data() {
    return {
      detailInfo: {},
      type: 'detail',
      form: {},
      projectLeaderOptions: [],
      saveLoading: false,
      projectTeamOptions: [],
    };
  },
  watch: {
    detailInfo: {
      handler(n) {
        this.form = this.filterDtailData(n);
      },
      immediate: true,
      deep: true,
    }
  },
  computed: {
    title() {
      if (this.$route.query &&  this.$route.query.projectName) {
        return this.$route.query.projectName
      }
      if (this.detailInfo && this.detailInfo.projectName) {
        return this.detailInfo.projectName
      }
      return ''
    },
    showAreaKM() {
      let inspectedArea = this.detailInfo.inspectedArea
      inspectedArea = Number(inspectedArea? inspectedArea: 0) / 1000 / 1000
      let targetArea =  this.detailInfo.targetArea
      targetArea = Number(targetArea? targetArea: 0) / 1000 / 1000
      var res = Number(inspectedArea.toFixed(2))
      return    `${res}/${Number(targetArea.toFixed(2))}`
    },
    showAreaMU() {
      let inspectedArea = this.detailInfo.inspectedArea
      inspectedArea = Number(inspectedArea? inspectedArea: 0) * 0.0015 / 10000
      let targetArea = this.detailInfo.targetArea
      targetArea =  Number(targetArea? targetArea: 0) * 0.0015 / 10000
      var res = Number(inspectedArea.toFixed(4))
      return   `${res}/${Number(targetArea.toFixed(2))}`
    },
    targetDuration() {
      let time = this.detailInfo && this.detailInfo.targetDuration?
        this.detailInfo.targetDuration: 0
      return this.filterFormatTime(time)
    },
    inspectedDuration() {
      let time = this.detailInfo && this.detailInfo.inspectedDuration?
        this.detailInfo.inspectedDuration: 0
      time = typeof time === 'string'? Number(time): time
      return this.filterFormatTimeBySeconds(time)
    },
    durationRatio() {
      let duration = this.detailInfo && this.detailInfo.inspectedDuration?
        this.detailInfo.inspectedDuration: 0
      duration = typeof duration === 'string' && Number(duration)? Number(duration) : duration
      let durationTotal = this.detailInfo && this.detailInfo.targetDuration?
        this.detailInfo.targetDuration: 0
      let ratio = duration * 100 / 3600 / durationTotal
      ratio = ratio? Number(ratio.toFixed(2)): 0
      return ratio
    },
    areaRatio() {
      let duration = this.detailInfo && this.detailInfo.inspectedArea?
        this.detailInfo.inspectedArea: 0
      duration = typeof duration === 'string' && Number(duration)? Number(duration) : duration
      let durationTotal = this.detailInfo && this.detailInfo.targetArea?
        this.detailInfo.targetArea: 0
      let ratio = duration / durationTotal
      ratio = ratio? Number(ratio.toFixed(2)): 0
      ratio = Number(ratio.toFixed(2))
      return ratio
    },
    startTime() {
      let time = this.detailInfo.startTime;
      time = time? time.split(' ')[0]: '-'
      return time
    },
    endTime() {
      let time = this.detailInfo.endTime;
      time = time? time.split(' ')[0]: '-'
      return time
    },
    ...mapGetters(["projectPoint"]),
  },
  created() {
    const projectId = this.$route.query.projectId
    this.type = this.$route.query.action? this.$route.query.action: 'detail'
    this.getProjectTeamOptions();
    this.getManagerlist();
    if (this.type !== 'add' && projectId) {
      getProjectDetail({id: projectId}).then((res) => {
      if (res.data.code === 200) {
        let data = res.data.data
        if (this.type === 'edit') {
          data.targetArea = data.targetArea / 1000 / 1000
        }
        this.detailInfo = res.data.data;
      }
    });
    }
  },
  methods: {
    backToList() {
      this.$router.push('/forest-grass/FG-Inspection');
    },
    getProjectTeamOptions() {
      getProjectTeamOptions().then((res) => {
        this.projectTeamOptions = this.filterListData(res.data.data)
      });
    },
    filterListData(arr) {
      let res = []
      for (var i = 0; i < arr.length; i++) {
        let item = arr[i]
        res.push(
            {
              label: item.title,
              value: item.value,
              children: (item.children && Array.isArray(item.children) && item.children.length > 0)?
                this.filterListData(item.children): undefined
            }
        )
      }
      return res
    },
    // 保存
    handleFinish() {
      this.saveLoading = true
      let data = JSON.parse(JSON.stringify(this.form))
      if (this.type !== "add") {
        if(data.uavList === null) data.uavList = [];
        if(data.linkList === null) data.linkList = [];
        if(data.podList === null) data.podList = [];
      }
      updateProject(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.$router.push(this.$route.matched[1].path);
          this.$store.dispatch("setProjectPoint", null);
          this.$store.dispatch("setRouteVisible", false);
        }
      }).catch((err) => {
        this.$message.error(err.msg);
      }).finally(()=>{
        this.saveLoading = false;
      })
    },
    // 项目成员
    getManagerlist() {
      getUser().then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          let arr = data.map((item) => {
            return {
              label: item.realName,
              value: item.id
            }
          });
          this.projectLeaderOptions = arr
        }
      });
    },
    // 过滤表单数据
    filterDtailData(detial) {
      var data = JSON.parse(JSON.stringify(detial))
      data.startTime = data.startTime? data.startTime: null
      data.endTime = data.endTime? data.endTime: null
      return data
    },
    filterFormatTime(time) {
      if (!time || time <= 0) {
        return "-";
      }
      var hours = Math.floor(time);
      var minutes = Math.floor((time - hours) * 60);
      var seconds = ((time - hours) * 60 - minutes) * 60
      hours = hours === 0 ? '': `${hours}h`
      minutes = minutes === 0 ? '': `${minutes}min`
      seconds = seconds === 0 ? '': `${seconds}s`
      return hours + minutes + seconds;
    },
    filterFormatTimeBySeconds(time) {
      if (!time || time <= 0) {
        return "-";
      }
      var hours = Math.floor(time / 3600);

      var minutes = Math.floor((time % 3600) / 60);

      var seconds = Math.floor((time % 60));
      hours = hours === 0 ? '': `${hours}h`
      minutes = minutes === 0 ? '': `${minutes}min`
      seconds = seconds === 0 ? '': `${seconds}s`
      return hours + minutes + seconds;
    }
  },
};
</script>
<style lang="scss" scoped>
.scroll-box-show {
  font-family: MicrosoftYaHeiUI;
  overflow: auto;
  color: #FFFFFF;
  height: 100%;
  .progress-box {
    padding: 14px 16px 28px 16px;
    .progress-title{
      height: 21px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 21px;
      margin-bottom: 22px;
    }
    .progress-line{
      display: flex;
      margin-top: 15px;
      &-label{
        height: 15px;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 15px;
        padding-right: 4px;
      }
      :last-child{
        flex-grow: 1;
      }
      /deep/.el-progress--line{
        display: flex;
        align-items: center;
        .el-progress-bar {
          flex-grow: 1;
        }
        .el-progress__text {
          color: #FFFFFF;
        }
      }
    }
  }
  .show-content{
    display: flex;
    &-left{
      width: 50%;
    }
    &-right{
      width: 50%;
    }
    .show-content-title {
      background-color: #030303;
      height: 32px;
      font-size: 14px;
      font-weight: bold;
      line-height: 32px;
      padding-left: 16px;
    }
    .show-content-value {
      height: 45px;
      font-size: 16px;
      line-height: 32px;
      padding-left: 16px;
    }
  }
}
.project-detail-view {
  position: relative;
  height: 100%;
  width: 100%;
  .detail-title {
    position: absolute;
    top: -49px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    .icon-back {
      margin-right: 4px;
    }
  }
  .scroll-box-edit {
    height: calc(100%);
    .from-box{
      overflow: auto;
      height: calc(100% - 59px);
      .from-name {
        .name-title {
          padding: 10px 5px 5px 17px;
          font-size: 12px;
          font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
          font-weight: bold;
          color: #ffffff;
          line-height: 18px;
          display: flex;
          justify-content: space-between;
          &-id {
            color: #76787d;
            font-size: 12px;
            font-weight: normal;
          }
        }
        .name-content {
          margin: 0 17px;
          background: #030303;
          font-size: 14px;
          font-family: MicrosoftYaHeiUI;
          color: #ffffff;
          line-height: 18px;
          /deep/.el-input__inner {
            border: none;
            color: #ffffff;
            text-align: left;
            background: transparent;
            width: 265px;
          }
        }
      }
    }
  }
  .finish {
    &-btn {
      width: 267px;
      height: 36px;
      line-height: 36px;
      background: #265edd;
      border-radius: 3px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding: 0;
      border: none;
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  .icon {
    cursor: pointer;

    &-edit {
      height: 13px;
    }

    &-delete {
      margin-left: 18px;
      height: 13px;
    }

    &-add {
      margin-left: 4px;
      height: 10px;
    }

    &-location {
      height: 18px;
      margin-right: 4px;
    }
  }
}
.required {
  color: red;
  margin-right: 5px;
}
</style>
